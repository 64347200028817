import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.less';
import { pageMetadataMap, PageType } from '@/shared/app-common';
import Image from 'next/image';
import { useUserProvider } from '@/context/UserProvider';
import { Carousel } from 'antd';
// import { useRouter } from 'next/router';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import { Playfair_Display } from 'next/font/google';
import { LibraryInfoModel } from '@/types/home';
import { set } from 'nprogress';

// 配置字体
const playfairdisplay = Playfair_Display({
  subsets: ['latin'], // 指定需要的字符子集
  weight: ['700'], // 指定需要的字体权重
  display: 'swap', // 优化字体显示的加载方式
});

const contentStyle: React.CSSProperties = {
  margin: 0,
  height: '36px',
  lineHeight: '36px',
  color: '#BAC3D0',
  fontSize: '14px',
};

const LoginStateInfo = ({
  pageType,
  infoData,
  onClick
}: {
  pageType?: PageType,
  infoData?: LibraryInfoModel,
  onClick?: (isDashboard: boolean, value?: string) => void
}) => {
  const [pageMetaData, _] = useState(pageMetadataMap[pageType ?? PageType.Normal])
  const { isLogin } = useUserProvider()
  const [showCarousel, setShowCarousel] = useState(true);
  const [searchValue, setSearchValue] = useState<string>(null);

  const list = useMemo(() => {
    return [
      {
        imgPath: '/images/home/home-AI-Analysis-icon.webp',
        title: 'AI Analysis',
      },
      {
        imgPath: '/images/home/home-Targeted-Monitoring-icon.webp',
        title: 'Targeted Monitoring',
      },
      {
        imgPath: '/images/home/home-Instant-Alerts-icon.webp',
        title: ('Instant Alerts'),
      },
      {
        imgPath: '/images/home/home-Visual-Result-Comparison-icon.webp',
        title: ('Visual Result Comparison'),
      },
    ]
  }, [])

  const onSubmit = () => {
    setTimeout(() => {
      onClick && onClick(false, searchValue?.trim())
    }, 1);
  }

  const getContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <div className={styles.topContent}>

          <h1 style={{ display: 'none', width: '0px', height: '0px' }}>
            {`The best tool for monitoring and analyzing`}
          </h1>

          <div className={styles.title}>{`The best tool for monitoring and analyzing`}</div>
          <div className={styles.title}>

            {/* {`Track Competitor Key Updates`.split('').map((char, index) => (
              <span
                key={index}
                style={{
                  animationDelay: `${index * 0.1}s`, // 每个字符的动画延迟
                }}
              >
                {char}
              </span>
            ))} */}

            {`Track Competitor Key Updates`
              .split(' ') // 先按单词分割
              .map((word, wordIndex) => (
                <span key={wordIndex} style={{ display: 'inline-block' }}>
                  {/* 遍历单词中的每个字符 */}
                  {word.split('').map((char, charIndex) => (
                    <span
                      key={charIndex}
                      style={{
                        animationDelay: `${(wordIndex * 10 + charIndex) * 0.1}s`, // 每个字符的动画延迟
                      }}
                    >
                      {char}
                    </span>
                  ))}
                  {/* 在单词后添加一个空格 */}
                  <span
                    style={{
                      animationDelay: `${(wordIndex * 10 + word.length) * 0.1}s`,
                    }}
                  >
                    &nbsp;
                  </span>
                </span>
              ))}

          </div>
          <div className={styles.desc}>{`Track website changes in real-time with instant alerts—never miss important updates`}</div>
          <div className={styles.desc1}>{`InstantKnow has detected`}</div>
          <div className={styles.valContainer}>
            <span className={styles.valCount}>{infoData?.website_num ?? 0}</span>
            <span>{`Projects`}</span>
            <span className={styles.valCount}>{infoData?.url_num ?? 0}</span>
            <span>{`URLs`}</span>
          </div>

          {/* <p>{pageMetaData.titleDescKey}</p> */}

          {/* {isLogin && <span
            className={styles.dashboard}
            onClick={() => {
              onClick && onClick(true, null)
            }}>{`Dashboard`}</span>} */}

          <div className={styles.searchContainer}>
            <Image src={'/images/home/link-icon.webp'} alt='link-icon' width={20} height={20} className={styles.searchIcon} />

            <div className={styles.carouselContainer}>
              <input
                autoFocus
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e?.target.value)}
                className={styles.inputContainer}
                placeholder="Enter the product page you want to stay updated on"
                onBlur={(e) => {
                  e?.stopPropagation()
                  setShowCarousel(true)
                }}
              />

              {/* {showCarousel && <Carousel
                dotPosition="left"
                dots={false}
                autoplay
              >
                {['product page',
                  'social media',
                  'website',
                  'news',
                  'event page'].map((item, index) => {
                    return <div
                      className={styles.carouselItem}
                      key={index}
                      onClick={(e) => {
                        e?.stopPropagation()
                        setShowCarousel(false)
                      }}>
                      <p style={contentStyle}>
                        Enter a <span style={{ color: '#1E5EFF' }}>{item}</span> URL:
                      </p>
                    </div>
                  })}
              </Carousel>} */}
            </div>

            <span className={styles.go} onClick={onSubmit}>
              {`Go >`}
            </span>
          </div>
        </div>

        <div className={styles.bottomContent}>
          {list?.map((item, index) => {
            return <div className={styles.listItem} key={item?.title}>
              <Image src={item?.imgPath} alt={''} width={48} height={48} />
              <span>{item?.title}</span>
            </div>
          })}
        </div>
      </div>
    );
  }, [pageMetaData, isLogin, showCarousel, searchValue]);

  return <>
    {getContent}
  </>;
};

export default LoginStateInfo;
